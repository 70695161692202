import type { FranchiseBlock } from 'components/plpContext/types/storePLP';
import type { FranchiseBlockFragment } from 'lib/contentful/__generated__/FranchiseBlock';

const formatIcons = (
  iconsCollection: FranchiseBlockFragment['iconsCollection']
) => {
  const icons: FranchiseBlock['icons'] = [];

  if (iconsCollection?.items?.length) {
    iconsCollection.items.forEach((icon) => {
      if (!icon || !icon.url) return;

      icons.push({
        url: icon.url,
        width: icon.width ?? 0,
        height: icon.height ?? 0,
        title: icon.title ?? '',
      });
    });
  }

  return icons;
};

export const formatFranchiseBlock = (
  entryContent: FranchiseBlockFragment | undefined
) => {
  if (!entryContent || !(entryContent.title || entryContent.description)) {
    return;
  }

  const theme: FranchiseBlock['theme'] =
    entryContent.theme === 'dark' ? 'dark' : 'light';

  const formattedData: FranchiseBlock = {
    title: entryContent.title ?? '',
    description: entryContent.description ?? '',
    featuresTitle: entryContent.featuresTitle ?? '',
    theme,
    icons: formatIcons(entryContent.iconsCollection),
    buttonText: entryContent.buttonText ?? '',
    buttonLink: entryContent.buttonLink ?? '',
  };

  return formattedData;
};
